<template>
  <section id="PageSingleTv" class="col-12 wrapper-text">
    <BioTv :tv="tv"></BioTv>
    <SeasonTv :tv="tv"></SeasonTv>
    <TrailersTv :tv="tv" :trailersTv="trailersTv"></TrailersTv>
    <SimilarTv :tv="tv" :similarTv="similarTv"></SimilarTv>
  </section>
</template>

<script>
import {defineAsyncComponent} from 'vue'

export default {
  name: "PageSingleTv",
  components: {
    'SimilarTv': defineAsyncComponent(() =>
        import('@/components/section/SimilarTv')
    ),
    'TrailersTv': defineAsyncComponent(() =>
        import('@/components/section/TrailersTv')
    ),
    'BioTv': defineAsyncComponent(() =>
        import('@/components/section/BioTv')
    ),
    'SeasonTv': defineAsyncComponent(() =>
        import('@/components/section/SeasonTv')
    )
  },
  watch: {
    "$route.params.id": function (id) {
      if (this.$route.params.id) {
        this.getTv(id);
        this.getTrailersTv(id);
        this.getSimilarTv(id);
      }
    },
  },
  computed: {
    tv() {
      return this.$store.state.getMovie.tv;
    },
    trailersTv() {
      return this.$store.getters["getMovie/trailersTvFilterd"];
    },
    similarTv() {
      return this.$store.getters["getMovie/similarTvFilterd"];
    },
  },
  methods: {
    getTv(id) {
      let query = `/tv/${id}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setTv",
      });
    },
    getTrailersTv(id) {
      let query = `/tv/${id}/videos?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setTrailersTv",
      });
    },
    getSimilarTv(id) {
      let query = `/tv/${id}/similar?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&page=1`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setSimilarTV",
      });
    },
  },
  mounted() {
    this.getTv(this.$route.params.id);
    this.getTrailersTv(this.$route.params.id);
    this.getSimilarTv(this.$route.params.id);
  },
  unmounted() {
    this.$store.commit("getMovie/setResetTv");
    this.$store.commit("getMovie/setResetTrailersTv");
    this.$store.commit("getMovie/setResetSimilarTV");
  },
};
</script>

<style scoped></style>
